import React from "react"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { FadeOnMount } from "@modules/animation"
import { Link } from "gatsby-interface"
import PropTypes from "prop-types"
import { useLocalSiteDetailsForPreviews } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { usePreview } from "@modules/build/shared/hooks/usePreview"
import Loading from "@modules/ui/components/Loading"
import BuildsTimeline from "@modules/build/list/components/BuildsTimeline"
import { BuildRunnerType, OrganizationStatus } from "@modules/graphql/types"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildTicTac from "@modules/site/cmsPreview/components/BuildTicTac"
import { PreviewTitle } from "@modules/site/cmsPreview/components/PreviewTitle"
import { mapBuildStatusToPreviewStatus } from "@modules/site/cmsPreview/helpers"
import { RestartPreviewButton } from "@modules/site/cmsPreview/components/RestartPreviewButton"
import { cmsPreview as cmsPreviewText } from "@modules/locales/default.js"

import {
  BuildsViewLayout,
  BuildViewHeading,
  BuildsViewHeader,
  BuildViewActions,
  BuildViewTitle,
  BuildViewInfo,
} from "./layouts"
import { getPreviewStatus } from "@modules/build/shared/utils"
import { EmptyState } from "gatsby-interface"
import { SitePermissions } from "../permissions"
import { FormattedMessage } from "@modules/locales"
import { getSiteDetailsTabBasePath } from "../details/utils"
import { SiteSettingsSection } from "../settings/constants"
import { SiteDetailsTab } from "../details/constants"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"

const propTypes = {
  organizationId: PropTypes.string,
  siteId: PropTypes.string,
}

const BUILD_PAGE_LIMIT = 10

export function PreviewsView({ siteId, organizationId }) {
  const triggerButtonRef = React.useRef()
  const [setError, errorAlert] = useTriggerErrorAlert()

  const siteDetails = useLocalSiteDetailsForPreviews(siteId)
  const {
    initialLoading,
    pageInfo,
    previews,
    loading,
    loadMore,
    lastPreview,
  } = usePreview(siteId)

  if (!siteDetails) {
    return null
  }

  if (initialLoading) {
    return (
      <FadeOnMount delay={500}>
        <Loading data-testid="previews-loading" />
      </FadeOnMount>
    )
  }

  if (!siteDetails.previewBuildsEnabled) {
    const settingsLink = `${getSiteDetailsTabBasePath(
      SiteDetailsTab.Settings,
      siteId,
      organizationId
    )}/${SiteSettingsSection.Preview}`
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            heading={cmsPreviewText.headers.previewBuildsDisabled}
            text={
              <SitePermissions
                id={siteId}
                resource="sites"
                action="edit"
                fallback={
                  cmsPreviewText.messages
                    .previewBuildsDisabledDescriptionReadAccess
                }
              >
                <FormattedMessage
                  message={
                    cmsPreviewText.messages.previewBuildsDisabledDescription
                  }
                  tags={{
                    link: function(content) {
                      return <Link to={settingsLink}>{content}</Link>
                    },
                  }}
                />
              </SitePermissions>
            }
            variant="BORDERED"
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const previewStatusFromBuild =
    (siteDetails?.incrementalPreviewsEnabled && lastPreview?.buildStatus) ||
    getPreviewStatus(lastPreview?.buildStatus, siteDetails?.previewStatus)
  const previewStatus =
    mapBuildStatusToPreviewStatus(previewStatusFromBuild) ||
    siteDetails?.previewStatus

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <BuildsViewLayout>
          <BuildViewHeading>
            <BuildsViewHeader>
              <BuildViewTitle>
                <PreviewTitle
                  orgStatus={siteDetails.organization?.status}
                  status={previewStatus}
                  url={siteDetails.previewUrl}
                  siteId={siteId}
                  incrementalPreviewsEnabled={
                    siteDetails.incrementalPreviewsEnabled
                  }
                />
              </BuildViewTitle>

              <BuildViewInfo>
                <BuildTicTac
                  a11yId={`PreviewsView--${siteId}--PreviewStatus`}
                  sitePreviewStatus={previewStatus}
                  siteBranch={siteDetails.branch}
                />
              </BuildViewInfo>

              <BuildViewInfo>
                <SiteBranch>{siteDetails.branch}</SiteBranch>
              </BuildViewInfo>
            </BuildsViewHeader>

            <BuildViewActions>
              <RestartPreviewButton
                ref={triggerButtonRef}
                siteId={siteId}
                organizationId={organizationId}
                disabled={
                  siteDetails.organization?.status === OrganizationStatus.Idle
                }
                setError={setError}
              />
            </BuildViewActions>
          </BuildViewHeading>

          {errorAlert}

          <BuildsTimeline
            siteId={siteId}
            organizationId={organizationId}
            repositoryUrl={siteDetails.repository?.url || ""}
            runnerType={BuildRunnerType.Preview}
            buildsListInfo={{ pageInfo, builds: previews }}
            loading={loading}
            fetchMore={loadMore}
            buildsPerPage={BUILD_PAGE_LIMIT}
            homeFocusTarget={triggerButtonRef.current}
            endFocusTarget={triggerButtonRef.current}
          />
        </BuildsViewLayout>
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}

PreviewsView.propTypes = propTypes
