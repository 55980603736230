import React from "react"
import PropTypes from "prop-types"
import Timeline from "@modules/ui/components/Timeline"
import PreviewBuildCard from "@modules/build/card/components/PreviewBuildCard"
import { Waypoint } from "react-waypoint"
import { fetchNextPageOnScroll } from "./BuildsTimeline.helpers"
import { BuildRunnerType } from "@modules/graphql/types"
import { useTheme, EmptyState } from "gatsby-interface"
import useMatchMedia from "@modules/ui/hooks/useMatchMedia"
import { getCardPadding } from "@modules/ui/stylesheets/card"
import Loading from "@modules/ui/components/Loading"
import { siteDetails as text } from "@modules/locales/default.js"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { isBuildRunnerType } from "@modules/build/shared/utils"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"

const propTypes = {
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  repositoryUrl: PropTypes.string,
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
  buildsListInfo: PropTypes.shape({
    builds: PropTypes.array,
    pageInfo: PropTypes.object,
  }),
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  buildsPerPage: PropTypes.number,
  homeFocusTarget: PropTypes.object,
  endFocusTarget: PropTypes.object,
  cdnVendor: PropTypes.string,
  onBuildSucceed: PropTypes.func,
}

export default function BuildsTimeline({
  siteId,
  organizationId,
  repositoryUrl,
  runnerType,
  buildsListInfo,
  loading,
  fetchMore,
  buildsPerPage,
  homeFocusTarget,
  endFocusTarget,
  cdnVendor,
  onBuildSucceed,
}) {
  const buildsList = buildsListInfo.builds || []
  const pageInfo = buildsListInfo.pageInfo
  const { mediaQueries } = useTheme()
  const useDesktopLayout = useMatchMedia(mediaQueries.tablet)
  const buildsCount = buildsList ? buildsList.length : 0

  if (!loading && buildsCount === 0) {
    return (
      <EmptyState
        graphic={<EmptyStateGraphic />}
        heading={
          isBuildRunnerType(runnerType)
            ? "No builds created!"
            : text.zeroBuildsTitle
        }
        headingAs="h2"
        text={
          runnerType === BuildRunnerType.Builds ? (
            <React.Fragment>
              To create a new build, push a new commit to your source control
              repository or trigger one using the according button.
            </React.Fragment>
          ) : (
            text.zeroBuildsText
          )
        }
        variant="BORDERED"
      />
    )
  }

  return (
    <div>
      <Timeline
        itemsLoaded={buildsCount}
        getConnectorAnchorOffset={PreviewBuildCard.getConnectorAnchorOffset}
        homeFocusTarget={homeFocusTarget}
        endFocusTarget={endFocusTarget}
        aria-label="Latest builds"
        aria-busy={loading}
        css={theme => {
          return [
            {
              display: `grid`,
              gridGap: theme.space[5],
            },
            !useDesktopLayout && getCardPadding(theme),
            !useDesktopLayout && {
              backgroundColor: theme.colors.white,
              borderTop: `1px solid ${theme.colors.blackFade[10]}`,
              borderBottom: `1px solid ${theme.colors.blackFade[10]}`,
              marginLeft: `-1.4rem`,
              marginRight: `-1.4rem`,
            },
          ]
        }}
      >
        {buildsList.map((build, idx) => (
          <Timeline.Item key={build.id}>
            <PreviewBuildCard
              {...build}
              cdnVendor={build.cdnVendor || cdnVendor}
              siteId={siteId}
              organizationId={organizationId}
              repositoryUrl={repositoryUrl}
              standalone={useDesktopLayout}
              aria-posinset={idx + 1}
              aria-setsize={pageInfo.hasNextPage ? -1 : buildsList.length}
              onBuildSucceed={onBuildSucceed}
              css={theme => ({
                marginBottom: theme.space[6],
                [theme.mediaQueries.tablet]: {
                  marginBottom: 0,
                },
              })}
            />
          </Timeline.Item>
        ))}
      </Timeline>
      <Waypoint
        onEnter={() => {
          if (pageInfo && pageInfo.hasNextPage) {
            fetchNextPageOnScroll(
              siteId,
              runnerType,
              pageInfo,
              fetchMore,
              buildsPerPage
            )
          }
        }}
      />
      <div
        aria-live="polite"
        role="status"
        css={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          minHeight: `5rem`,
        }}
      >
        {loading ? (
          <Loading
            variant="baby"
            message={text.loadingMoreBuilds}
            data-testid="builds-loading-more"
          />
        ) : (
          <span css={visuallyHiddenCss}>{text.buildsLoaded}</span>
        )}
      </div>
    </div>
  )
}

BuildsTimeline.propTypes = propTypes
