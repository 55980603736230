import * as React from "react"
import {
  Heading,
  ThemeCss,
  Link,
  Spacer,
  Badge,
  Tooltip,
} from "gatsby-interface"
import { Site, Build, CdnVendor } from "@modules/graphql/types"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import { isProperlyConnected } from "@modules/site/cdnIntegrations"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { MdSettings } from "react-icons/md"
import {
  getSiteDetailsTabBasePath,
  getPathToBuildsForBranch,
} from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import {
  deploysView as deploysViewText,
  ui as uiText,
} from "@modules/locales/default.js"
import { SiteBuildsLastBuild } from "./SiteBuildsLastBuild"
import { SiteBuildsPublishedBuild } from "./SiteBuildsPublishedBuild"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { descriptionCss } from "@modules/site/shared/stylesheets/siteBuildsBuildSection"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { useBuilds } from "../hooks/useDeploys"

export type SiteBuildsProductionProps = {
  organizationId: string
  siteId: string
  siteData: Site
  lastBuild: Build
  repositoryUrl: string
  isEligiblePlan: boolean
  refetchSiteDetails?: () => void
}

export function SiteBuildsProduction({
  siteId,
  organizationId,
  siteData,
  lastBuild,
  repositoryUrl,
  refetchSiteDetails,
  isEligiblePlan,
}: SiteBuildsProductionProps) {
  const [
    triggerBuildError,
    setTriggerBuildError,
  ] = React.useState<JSX.Element | null>(null)
  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )
  const cdnIntegrations = siteData?.cdnIntegrations ?? []
  const latestHostingDeployVersion = siteData?.latestHostingDeployVersion
  const manualHostingDeploysEnabled = siteData?.manualHostingDeploysEnabled
  const activeIntegration = cdnIntegrations.find(isProperlyConnected)
  const gatsbyHostingOn = activeIntegration?.vendor === CdnVendor.CloudCdn
  const productionBranch = siteData.branch

  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )
  const linkToHistory = getPathToBuildsForBranch(
    productionBranch,
    siteId,
    organizationId
  )

  const [builds] = useBuilds(
    siteId,
    productionBranch,
    !latestHostingDeployVersion
  )

  const publishedBuildPosition = builds.findIndex(
    item => item?.id === latestHostingDeployVersion
  )
  const lastBuildPosition = builds.findIndex(item => item?.id === lastBuild?.id)

  return (
    <React.Fragment>
      <div>
        <Heading as="h2" css={headingCss} fontVariant="UI">
          {deploysViewText.headers.production}
        </Heading>
        <div css={topCss}>
          <div css={siteMeta}>
            <SiteBranch>
              <strong>{productionBranch}</strong>
            </SiteBranch>

            {(!gatsbyHostingOn || !isEligiblePlan) && lastBuild?.endedAt && (
              <BuildPublishedDate buildPublishedDate={lastBuild.endedAt} />
            )}

            {gatsbyHostingOn && isEligiblePlan && (
              <span css={deploysCss}>
                <GatsbyCloudLogo /> {deploysViewText.labels.automaticDeploys}:
                <Spacer size={3} direction="horizontal" />
                <Badge
                  tone={
                    siteData?.manualHostingDeploysEnabled ? `BRAND` : `SUCCESS`
                  }
                >
                  {siteData?.manualHostingDeploysEnabled
                    ? uiText.labels.disabled
                    : uiText.labels.enabled}
                </Badge>
                <Tooltip label={uiText.actions.editSettings}>
                  <span css={settingsLinkCss}>
                    <Link to={`${linkToSiteSettings}/builds`} variant="SIMPLE">
                      <MdSettings />
                    </Link>
                  </span>
                </Tooltip>
              </span>
            )}
          </div>
          <div css={actionsCss}>
            <Link to={linkToHistory} variant="SIMPLE">
              {deploysViewText.labels.viewProductionHistory}
            </Link>

            <TriggerBuild
              siteId={siteId}
              selectedBranch={productionBranch}
              orgStatus={siteData.organization?.status}
              size={`M`}
              triggerBuildError={triggerBuildError}
              setTriggerBuildError={setTriggerBuildError}
            />
          </div>
        </div>

        {triggerBuildError && (
          <React.Fragment>
            <Spacer size={7} />
            {triggerBuildError}
          </React.Fragment>
        )}

        {gatsbyHostingOn && isEligiblePlan && (
          <SiteBuildsPublishedBuild
            siteId={siteId}
            siteData={siteData}
            organizationId={organizationId}
            buildId={latestHostingDeployVersion ?? undefined}
            gatsbyHostingOn={gatsbyHostingOn}
            isEligiblePlan={isEligiblePlan}
            repositoryUrl={repositoryUrl}
          />
        )}

        {lastBuild && (
          <SiteBuildsLastBuild
            siteId={siteId}
            buildData={lastBuild}
            siteData={siteData}
            organizationId={organizationId}
            latestHostingDeployVersion={latestHostingDeployVersion ?? undefined}
            gatsbyHostingOn={gatsbyHostingOn}
            onPublish={setBuildToPublish}
            onBuildSucceed={refetchSiteDetails}
            isEligiblePlan={isEligiblePlan}
            repositoryUrl={repositoryUrl}
            publishedBuildPosition={publishedBuildPosition}
            lastBuildPosition={lastBuildPosition}
            linkToHistory={linkToHistory}
            manualHostingDeploysEnabled={
              manualHostingDeploysEnabled ?? undefined
            }
          />
        )}

        {lastBuild && siteData?.stableBuildURL && (
          <div css={stableBuildLink}>
            Latest successful build always at&nbsp;
            <ExternalLink href={siteData?.stableBuildURL} truncate size={1} />
          </div>
        )}
      </div>
      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </React.Fragment>
  )
}

/* styles */

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
})

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[5],
  marginTop: theme.space[5],

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr auto`,
  },
})

const siteMeta: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  flexDirection: `row`,
  flexWrap: `wrap`,

  "& > span": {
    minHeight: theme.space[7],
    marginRight: theme.space[7],
  },
})

const deploysCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    width: `1.25em`,
    height: `1.25em`,
    marginRight: theme.space[3],
  },
})

const settingsLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: `.8em`,
    marginLeft: theme.space[2],
    color: theme.colors.grey[40],
  },

  "&:hover svg": {
    color: theme.colors.purple[40],
  },
})

const actionsCss: ThemeCss = theme => ({
  display: `inline-grid`,
  gap: theme.space[6],
  gridTemplateColumns: `auto auto`,
  justifyContent: `end`,

  a: {
    lineHeight: theme.lineHeights.solid,
  },
})

const stableBuildLink: ThemeCss = theme => [
  descriptionCss(theme),
  {
    marginTop: theme.space[7],
    justifyContent: `flex-end`,

    a: {
      fontWeight: theme.fontWeights.body,
    },
  },
]
