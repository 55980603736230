/* @typescript-eslint/no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import { BuildRunnerType, BuildType, BuildStatus } from "@modules/graphql/types"
import useBuildChangedSubscription from "@modules/build/shared/hooks/useBuildChangedSubscription"

import BuildCardMetricsRow from "./BuildCardMetricsRow"
import BuildCardStatusInfo from "./BuildCardStatusInfo"
import BuildDetailsLink from "./BuildDetailsLink"
import BuildCommitInfo from "@modules/build/shared/components/BuildCommitInfo"
import { BuildEventText } from "@modules/build/shared/components/BuildEventText"
import { BuildWarnings } from "@modules/build/shared/components/BuildWarnings"
import BuildAuthor from "./BuildAuthor"
import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import {
  isBuildRunnerType,
  isIncrementalUpdate,
} from "@modules/build/shared/utils"
import {
  baseCardCss,
  statusIndicatorCellCss,
  statusIndicatorIconCss,
  statusCellCss,
  startDateCellCss,
  eventTextCellCss,
  eventTextCss,
  incrementalBuildChipCss,
  warningsCellCss,
  authorCellCss,
  commitInfoCss,
  detailsLinkCellCss,
  detailsLinkCss,
  deploymentStatusCss,
  deploymentTimingCss,
} from "./BuildCard.styles"
import useCardClickHandlers from "@modules/ui/hooks/useCardClickHandlers"
import useHoveredState from "@modules/ui/hooks/useHoveredState"
import IncrementalBuildChip from "@modules/build/shared/components/IncrementalBuildChip"
import { DeploymentStatus } from "./DeploymentStatus"
import { DeploymentTiming } from "./DeploymentTiming"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { deploysView as deploysViewText } from "@modules/locales/default.js"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate"

const propTypes = {
  id: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  repositoryUrl: PropTypes.string,
  buildStatus: PropTypes.oneOf(Object.values(BuildStatus)).isRequired,
  buildType: PropTypes.oneOf(Object.values(BuildType)).isRequired,
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
  deployStartedAt: PropTypes.string,
  deployEndedAt: PropTypes.string,
  duration: PropTypes.number,
  branch: PropTypes.string,
  commit: PropTypes.shape({
    sha: PropTypes.string,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    message: PropTypes.string,
  }),
  author: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  className: PropTypes.string,
  standalone: PropTypes.bool,
  clickable: PropTypes.bool,
  cdnVendor: PropTypes.string,
  onBuildSucceed: PropTypes.func.isRequired,
}

export default function PreviewBuildCard({
  id,
  siteId,
  organizationId,
  buildStatus,
  buildType,
  runnerType,
  createdAt,
  startedAt,
  endedAt,
  duration,
  branch,
  commit,
  source,
  author,
  className,
  standalone = true,
  clickable = true,
  deployStartedAt,
  deployEndedAt,
  cdnVendor,
  repositoryUrl,
  onBuildSucceed,
  pullRequest: _pr,
  ...props
}) {
  const isBuildCard = isBuildRunnerType(runnerType)
  const creationDate = getContextuallyFormatedDate(createdAt)

  const showDeploymentStatus = isBuildCard && deployStartedAt

  useBuildChangedSubscription(id, runnerType, onBuildSucceed)

  const { hovered, handlers: hoverHandlers } = useHoveredState()
  const { clickableElementRef, cardProps } = useCardClickHandlers()
  const cardClickProps = clickable ? cardProps : {}
  const a11yLabelId = `build-${id}__status`

  const { structuredBuild: _, ...rest } = props

  return (
    <div
      data-buildid={id}
      data-cy="build-card"
      css={theme => [
        baseCardCss(theme, standalone),
        clickable && {
          transition: `box-shadow ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
          "&:hover": {
            boxShadow: theme.shadows.floating,
          },
          cursor: `pointer`,
        },
      ]}
      role="article"
      aria-labelledby={a11yLabelId}
      // Adding tabIndex for feed keyboard interaction https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-9
      tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      className={className}
      {...rest}
      {...cardClickProps}
      {...hoverHandlers}
    >
      <div
        css={statusIndicatorCellCss}
        data-cy={`build-card-status`}
        id={a11yLabelId}
      >
        <BuildStatusIndicator
          a11yId={id}
          buildStatus={buildStatus}
          runnerType={runnerType}
          branch={branch}
          css={statusIndicatorIconCss}
        />
      </div>
      <div css={statusCellCss}>
        <BuildCardStatusInfo
          buildStatus={buildStatus}
          createdAt={createdAt}
          startedAt={startedAt}
          endedAt={endedAt}
          duration={duration}
        />
      </div>
      {showDeploymentStatus && (
        <div css={deploymentTimingCss}>
          <DeploymentTiming
            status={buildStatus}
            startDate={new Date(deployStartedAt)}
            endDate={deployEndedAt ? new Date(deployEndedAt) : undefined}
            vendor={cdnVendor || ""}
          />
        </div>
      )}
      <div css={eventTextCellCss} data-cy="build-triggering-event">
        <div css={eventTextCss}>
          <BuildEventText
            commit={commit}
            buildType={buildType}
            buildSource={source}
            runnerType={runnerType}
          />
        </div>
        {isIncrementalUpdate(runnerType, buildType) && (
          <IncrementalBuildChip css={incrementalBuildChipCss} />
        )}
      </div>
      <div css={warningsCellCss}>
        {isBuildCard && <BuildWarnings siteId={siteId} buildId={id} />}
      </div>
      {showDeploymentStatus && (
        <div css={deploymentStatusCss}>
          <DeploymentStatus value={buildStatus} />
        </div>
      )}
      <div css={authorCellCss}>
        <BuildAuthor
          buildType={buildType}
          commit={commit}
          author={author}
          source={source}
          css={theme => ({
            marginRight: theme.space[5],
          })}
        />
        <div css={startDateCellCss}>
          {creationDate && (
            <React.Fragment>
              <span css={visuallyHiddenCss}>
                {deploysViewText.messages.buildTriggered}
              </span>
              <span>{creationDate}</span>
            </React.Fragment>
          )}
        </div>

        <BuildCommitInfo
          branch={branch}
          commit={commit}
          repositoryUrl={repositoryUrl || ""}
          css={commitInfoCss}
          withBranchName={false}
        />
      </div>
      <div css={detailsLinkCellCss}>
        {clickable && (
          <BuildDetailsLink
            id={id}
            siteId={siteId}
            organizationId={organizationId}
            ref={clickableElementRef}
            variant={hovered ? `PRIMARY` : `SECONDARY`}
          >
            <span css={detailsLinkCss}>View Details</span>
          </BuildDetailsLink>
        )}
      </div>
      {isBuildCard && (
        <BuildCardMetricsRow siteId={siteId} buildId={id} branch={branch} />
      )}
    </div>
  )
}

PreviewBuildCard.getConnectorAnchorOffset = card => {
  const statusIcon = card.querySelector(`[data-build-status-icon]`)

  return {
    left: statusIcon.parentNode.offsetLeft + statusIcon.clientWidth / 2,
    top: statusIcon.parentNode.offsetTop + statusIcon.clientHeight / 2,
  }
}

PreviewBuildCard.propTypes = propTypes
