import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { navigate } from "@reach/router"
import Loading from "@modules/ui/components/Loading"
import SiteDetailsTabsNav from "@modules/site/details/components/SiteDetailsTabsNav"
import { OrganizationStatus, CdnVendor } from "@modules/graphql/types"
import {
  SiteInformationViewLayout,
  SiteInformationViewGrid,
  SiteInformationViewSiteNameArea,
  SiteInformationViewDescriptionArea,
  SiteInformationViewHeader,
  SiteInformationViewDetailsArea,
} from "../layouts"
import { CmsChip } from "@modules/site/informationView/components/InformationViewChips"
import OrganizationStatusNotification from "@modules/organization/shared/components/OrganizationStatusNotification"
import { SiteDescription } from "@modules/site/informationView/components/SiteDescription"
import { SiteDirectoryPath } from "@modules/site/informationView/components/SiteDirectoryPath"
import { TabRouter } from "@modules/site/informationView/components/TabRouter"
import { useSiteDetails } from "../hooks/useSiteDetails"
import { getSiteDetailsTabs } from "@modules/site/details/utils"
import { useFlags } from "@modules/featureFlags"
import { hasSourcePullRequestPermission } from "@modules/site/shared/helpers"
import { PullRequestPermissionBanner } from "./PullRequestPermissionBanner"
import { DetailsViewHeader } from "@modules/ui/components/DetailsViewHeader"
import { sites as sitesText } from "@modules/locales/default.js"
import { RepositoryChip } from "@modules/site/shared/components/RepositoryChip"
import { SourceControlProvider } from "@modules/graphql/types"
import { MdLockOutline } from "react-icons/md"
import { EmptyState, Spacer, EmptyStatePrimaryAction } from "gatsby-interface"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { getPathToOrgSites } from "@modules/organization/shared/utils"
import { CdnIntegrationChip } from "@modules/cdn/shared/components/CdnIntegrationChip"
import { SitePermissions } from "@modules/site/permissions"
import { SiteHostingInfo } from "@modules/cdn/gatsbyCloudCdn/components/SiteHostingInfo"
import { useHostingDetailsForSiteQuery } from "@modules/cdn/gatsbyCloudCdn/queries.generated"
import { HostingBanner } from "./HostingBanner"
import { useSitePlatformLimitSummariesQuery } from "@modules/site/queries.generated"
import { getPlatformLimitOveragesMessage } from "@modules/organization/shared/utils/getPlatformLimitOveragesMessage"
import { PlatformLmitOverageNotification } from "@modules/organization/shared/components/PlatformLmitOverageNotification"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { isProperlyConnected } from "@modules/site/cdnIntegrations"
import { StatusPageBanner } from "@modules/status/components/StatusPageBanner"
import { BannersContainer } from "@modules/ui/components/BannersContainer"

export const hostingInfoCss = theme => ({
  marginBottom: theme.space[4],
})

const propTypes = {
  organizationId: PropTypes.string,
  siteId: PropTypes.string,
}

export function SiteInformationView({ siteId, organizationId }) {
  const siteDetails = useSiteDetails(siteId)
  const cdnIntegrations = siteDetails?.cdnIntegrations ?? []
  const activeIntegration = cdnIntegrations.find(isProperlyConnected)
  const gatsbyHostingOn = activeIntegration?.vendor === CdnVendor.CloudCdn
  const pathToOrgSettings = getPathToOrgSettings(organizationId)

  const { flags } = useFlags()

  const { data: hostingDetails } = useHostingDetailsForSiteQuery({
    variables: { siteId },
    fetchPolicy: `cache-and-network`,
    skip: !gatsbyHostingOn,
  })

  const { data: limitSummariesData } = useSitePlatformLimitSummariesQuery({
    variables: { workspaceId: organizationId, siteId },
    fetchPolicy: "cache-and-network",
  })

  const platformLimitSummaries = (
    limitSummariesData?.platformLimitSummaries || []
  ).filter(Boolean)

  const platformLimitOveragesMessage = getPlatformLimitOveragesMessage(
    platformLimitSummaries
  )

  const hostingDetailsForSite = hostingDetails?.hostingDetailsForSite?.[0]

  const tabs = getSiteDetailsTabs(flags, {
    buildsEnabled: siteDetails?.buildsEnabled,
    permissions: siteDetails?.permissions,
  })

  if (siteDetails?.error?.message) {
    const pathToOrgSites = getPathToOrgSites(organizationId)
    if (
      siteDetails?.error?.message.includes(
        `You do not have permissions to view this Site`
      )
    ) {
      return (
        <StandardSingleColumn>
          <Spacer size={12} />

          <EmptyState
            graphic={<MdLockOutline />}
            variant="BORDERED"
            heading={sitesText.headers.noAccess}
            headingAs="h3"
            text={sitesText.messages.contactTheOwner}
            primaryAction={
              <EmptyStatePrimaryAction to={pathToOrgSites}>
                {sitesText.actions.backToHomepage}
              </EmptyStatePrimaryAction>
            }
          />
        </StandardSingleColumn>
      )
    }

    navigate(pathToOrgSites)
    return null
  }

  if (siteDetails.loading) {
    return <Loading delay={500} data-testid="information-view-loading" />
  }

  const hasPullRequestPermissions = hasSourcePullRequestPermission(
    siteDetails?.sourceOrganization?.permissions || []
  )

  const siteDescriptionElement = (
    <SiteDescription siteId={siteId} organizationId={organizationId}>
      {siteDetails.siteDescription}
    </SiteDescription>
  )

  const siteRepositoryElement = siteDetails.repository && (
    <RepositoryChip
      // Fallback to Github until repository.provider is properly implemented
      provider={siteDetails.repository.provider || SourceControlProvider.Github}
      repositoryUrl={siteDetails.repository.url}
      data-testid="information-view-fullname"
      css={theme => ({ marginRight: theme.space[3] })}
    />
  )

  const siteCmsElement = siteDetails.cmsIntegrations?.length > 0 && (
    <div data-testid="information-view-cms-integrations">
      {siteDetails.cmsIntegrations.map(vendor => (
        <CmsChip
          key={vendor.id}
          vendor={vendor.name}
          formattedName={vendor.formattedName}
          url={vendor.config?.managementUrl}
        />
      ))}
    </div>
  )

  const siteDirectoryElement = siteDetails.siteDirectoryPath && (
    <SiteDirectoryPath path={siteDetails.siteDirectoryPath} />
  )

  const siteName = siteDetails.site?.publicName || siteDetails.site?.name

  return (
    <main>
      {siteName && (
        <Helmet
          titleTemplate={`Gatsby Cloud | ${siteName} | %s`}
          defaultTitle={`Gatsby Cloud | ${siteName}`}
        />
      )}
      <SiteInformationViewLayout>
        <SiteInformationViewHeader>
          <BannersContainer>
            <StatusPageBanner />

            {!hasPullRequestPermissions && (
              <SitePermissions id={siteId} resource="sites" action="edit">
                <PullRequestPermissionBanner
                  permissionsUrl={
                    siteDetails?.sourceOrganization?.permissionsURL
                  }
                />
              </SitePermissions>
            )}

            {siteDetails.orgStatus !== OrganizationStatus.Active && (
              <React.Fragment>
                <OrganizationStatusNotification
                  organizationId={organizationId}
                  status={siteDetails.orgStatus}
                />
              </React.Fragment>
            )}

            <HostingBanner
              siteId={siteId}
              organizationId={organizationId}
              gatsbyHostingOn={gatsbyHostingOn}
              hostingDetails={hostingDetailsForSite}
            />

            {flags.multiTiersPlans && platformLimitOveragesMessage && (
              <PlatformLmitOverageNotification
                message={platformLimitOveragesMessage}
                link={pathToOrgSettings}
              />
            )}
          </BannersContainer>

          <SiteInformationViewGrid>
            <SiteInformationViewSiteNameArea>
              <DetailsViewHeader eyebrow={sitesText.morphemes.site}>
                {siteName}
              </DetailsViewHeader>
            </SiteInformationViewSiteNameArea>
            <SiteInformationViewDescriptionArea>
              {siteDescriptionElement}
            </SiteInformationViewDescriptionArea>
            <SiteInformationViewDetailsArea>
              <div
                css={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  alignItems: `center`,
                }}
              >
                {siteRepositoryElement}
                {siteCmsElement}
                {activeIntegration?.vendor && (
                  <React.Fragment>
                    <CdnIntegrationChip
                      vendor={activeIntegration.vendor}
                      siteId={siteId}
                      organizationId={organizationId}
                    />
                    <Spacer size={3} direction="horizontal" />
                  </React.Fragment>
                )}
                {siteDirectoryElement && (
                  <div
                    css={theme => ({
                      marginTop: theme.space[3],
                      marginBottom: theme.space[3],
                    })}
                  >
                    {siteDirectoryElement}
                  </div>
                )}
              </div>
            </SiteInformationViewDetailsArea>
          </SiteInformationViewGrid>

          <SiteHostingInfo
            siteId={siteId}
            organizationId={organizationId}
            hostingDetails={hostingDetailsForSite}
            gatsbyHostingOn={gatsbyHostingOn}
            css={hostingInfoCss}
            platformLimitSummaries={platformLimitSummaries}
          />
        </SiteInformationViewHeader>

        <SiteDetailsTabsNav
          organizationId={organizationId}
          siteId={siteId}
          tabs={tabs}
        />

        <TabRouter
          siteId={siteId}
          organizationId={organizationId}
          tabs={tabs}
        />
      </SiteInformationViewLayout>
    </main>
  )
}

SiteInformationView.propTypes = propTypes
