import { ThemeCss } from "gatsby-interface"

export const rootCss: ThemeCss = theme => ({
  marginTop: theme.space[6],
  margintBottom: theme.space[9],
})

export const descriptionCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  marginBottom: theme.space[4],
  display: `flex`,
  alignItems: `center`,
  marginTop: theme.space[8],
  flexWrap: `wrap`,
})

export const descriptionItemCss: ThemeCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    marginRight: theme.space[3],
    color: theme.colors.grey[50],
  },
})

export const separatorCss: ThemeCss = theme => ({
  color: theme.colors.grey[40],
  display: `inline-block`,
  margin: `0 ${theme.space[3]}`,
})
