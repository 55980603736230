import React from "react"
import { Router, Redirect } from "@reach/router"
import { DeploysPane } from "@modules/site/details/components/DeploysPane"
import SiteSettingsPane from "@modules/site/details/components/SettingsPane"
import { PreviewsView } from "@modules/site/buildsView/PreviewsView"
import { getPathToSiteDetailsTab } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"

const paneComponentsByTab = {
  [SiteDetailsTab.Settings]: SiteSettingsPane,
  [SiteDetailsTab.Deploys]: DeploysPane,
  [SiteDetailsTab.CmsPreview]: PreviewsView,
}

export function TabRouter({ siteId, organizationId, tabs }) {
  return (
    <Router>
      {tabs.map(({ tab, hasSections }) => {
        const Pane = paneComponentsByTab[tab]
        return (
          <Pane key={tab} path={hasSections ? `${tab}/:section` : `${tab}/*`} />
        )
      })}
      {tabs
        .filter(
          ({ hasSections, initialSection }) => hasSections && initialSection
        )
        .map(({ tab, initialSection }) => (
          <Redirect
            key={`${tab}/${initialSection}`}
            from={`${tab}/`}
            to={getPathToSiteDetailsTab(tab, siteId, organizationId)}
          />
        ))}

      {tabs.length > 0 && (
        <Redirect
          from={`/`}
          to={getPathToSiteDetailsTab(tabs[0].tab, siteId, organizationId)}
          noThrow
        />
      )}
    </Router>
  )
}
