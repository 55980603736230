import * as React from "react"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { Site, Build, BuildStatus } from "@modules/graphql/types"
import { getPathToBuildDetails } from "@modules/site/details/utils"
import { GoServer } from "react-icons/go"
import { DateTimeDistanceToNow } from "@modules/ui/components/DateTimeDistance"
import { Link } from "gatsby-interface"
import {
  rootCss,
  descriptionCss,
  separatorCss,
  descriptionItemCss,
} from "@modules/site/shared/stylesheets/siteBuildsBuildSection"

export type SiteBuildsLastBuildProps = {
  buildData: Build
  organizationId: string
  siteId: string
  siteData: Site
  gatsbyHostingOn: boolean
  onPublish: React.Dispatch<React.SetStateAction<string | null>>
  repositoryUrl: string
  linkToHistory: string
  isEligiblePlan: boolean
  manualHostingDeploysEnabled?: boolean
  latestHostingDeployVersion?: string
  onBuildSucceed?: () => void
  publishedBuildPosition?: number
  lastBuildPosition?: number
}

export function SiteBuildsLastBuild({
  buildData,
  siteId,
  organizationId,
  siteData,
  gatsbyHostingOn,
  onPublish,
  latestHostingDeployVersion,
  onBuildSucceed,
  repositoryUrl,
  linkToHistory,
  publishedBuildPosition,
  lastBuildPosition,
  manualHostingDeploysEnabled,
  isEligiblePlan,
}: SiteBuildsLastBuildProps) {
  if (
    gatsbyHostingOn &&
    isEligiblePlan &&
    buildData &&
    buildData.id === latestHostingDeployVersion
  ) {
    return null
  }

  const distance =
    !!latestHostingDeployVersion &&
    publishedBuildPosition !== undefined &&
    publishedBuildPosition >= 0 &&
    lastBuildPosition !== undefined &&
    lastBuildPosition >= 0
      ? -(lastBuildPosition - publishedBuildPosition)
      : null

  const positionInfo = distance ? (
    <React.Fragment>
      {`it's`}{" "}
      <Link to={linkToHistory} variant="SIMPLE">
        {distance} {distance > 1 ? `builds` : `build`} ahead
      </Link>{" "}
      of the published one
    </React.Fragment>
  ) : null

  return (
    <div css={rootCss}>
      {gatsbyHostingOn && isEligiblePlan && (
        <div css={descriptionCss}>
          <span css={descriptionItemCss}>
            <GoServer /> Last build
          </span>
          <span css={separatorCss}>•</span>
          <span>
            {buildData?.buildStatus === BuildStatus.Success ? (
              <React.Fragment>
                built <DateTimeDistanceToNow fromDate={buildData.endedAt} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                triggered{" "}
                <DateTimeDistanceToNow fromDate={buildData.createdAt} />
              </React.Fragment>
            )}
          </span>
          {positionInfo && (
            <React.Fragment>
              <span css={separatorCss}>•</span>
              <span>{positionInfo}</span>
            </React.Fragment>
          )}
        </div>
      )}
      {buildData && (
        <BuildCard
          dataTestid="latest-builds-for-production-branch"
          id={`builds-production-branch-${buildData?.id}-last`}
          siteId={siteId}
          organizationId={organizationId}
          buildId={buildData.id}
          status={buildData?.buildStatus}
          createdAt={buildData.createdAt}
          startedAt={buildData.startedAt}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          duration={buildData.duration || undefined}
          endedAt={buildData.endedAt}
          latestHostingDeployVersion={latestHostingDeployVersion}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled}
          gatsbyHostingOn={gatsbyHostingOn}
          isEligiblePlan={isEligiblePlan}
          onPublish={onPublish}
          branch={siteData.branch}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          commit={buildData.commit || undefined}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          runnerType={buildData.runnerType!}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          buildType={buildData.buildType!}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          source={buildData.source || undefined}
          /* backend allows for null. Fallbacking to undefined for safe types in child components */
          author={buildData.author || undefined}
          isProductionBranch={true}
          viewDetailsHref={getPathToBuildDetails(
            buildData?.id,
            siteId,
            organizationId
          )}
          deployStartedAt={buildData?.deployStartedAt}
          deployEndedAt={buildData?.deployEndedAt}
          onBuildSucceed={onBuildSucceed}
          repositoryUrl={repositoryUrl}
          routeMetadata={buildData?.routeMetadata || undefined}
        />
      )}
    </div>
  )
}
